<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="syncroSatModalModal"
    >
      <CRow>
        <CCol md="12" >
            <CRow class="content-sat" style="padding-left: 60px; padding-right: 60px;">
              <CCol md="12" class="text-center" style="width: 150px; padding: 10px;">
                <img src="/img/sat-logo.png" style="width: 150px;">
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>RFC RECEPTOR:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{fiscal.rfc}}</span>
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>Última Actualización:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.last_start_date}} - {{peticion.last_end_date}}</span>
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>Estado de la petición:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.status_legend}}</span>
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>CFDI'S encontrados:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.cfdis}}</span>
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>CFDI'S de Ingreso:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.cfdis_income}}</span>
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>CFDI'S de Egreso:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.cfdis_expense}}</span>
              </CCol>
              <CCol md="6" class="text-center">
                <span for=""><strong>CFDI'S de Pagos:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.cfdis_payment}}</span>
              </CCol>  
              <CCol md="6" class="text-center">
                <span for=""><strong>CFDI'S de Nómina:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.cfdis_payroll}}</span>
              </CCol>  
              <CCol md="6" class="text-center">
                <span for=""><strong>CFDI's que no se importaron con éxito:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{peticion.cfdis_error}}</span>
              </CCol>      
              <CCol md="6" class="text-center">
                <span for=""><strong>Nuevo Periodo:</strong></span>
              </CCol>
              <CCol md="6" class="text-center">
                <span>{{range[0] | date}} - {{range[1] | date}}</span>
              </CCol>                                                                                                                      
            </CRow>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="syncroSat" :disabled="peticion.period_complete == true" :color="color">Sincronizar</CButton>
        </template>
    </CModal>
</template>

<script>
import ws from '../../../services/cfdis-import';
import ws_account from '../../../services/account';
import store from '../../../store'
import moment from 'moment';

export default { 
  name: 'SyncroSatModal',
  props: {

  },
  components: {
  },
  data () {
    return {
      syncroSatModalModal: false,
      title: "Importar CFDI'S", 
      color: "info",
      range: [],
      fiscal: {
        rfc :"",
      },
      filters:{},
      peticion: {
        status: "",
        last_start_date: "",
        last_end_date: "",
        status_legend: "Sin realizar",
        cfdis: 0,
        cfdis_income: 0,
        cfdis_expense: 0,
        cfdis_payment: 0,
        cfdis_payroll: 0,
        cfdis_error: 0,
        cfdis_already_exists: 0,
        },
      is_first: false,      
    }
  },
  mounted: async function() {
    let response = await ws_account.getFiscalInfo();
    if(response.type == "success"){
      this.fiscal = response.data;
    }
  },
  methods: {
    async syncroSat () {
      this.loading();
        let response = await ws.downloadMasive(this.filters);
        if(response.type == "success"){
          this.loaded();
          this.showToast(response.type, response.message);
          this.closeModal();
        }else{
          this.loaded();
          this.showToast(response.type, response.message);
        }
      
    },
    async openModal (filters) {
      this.syncroSatModalModal = true;
      this.color = "info";
      this.title = 'Importar CFDI\'S';
      this.filters = filters;
      this.range = [
        moment(filters.date[0]).format("DD/MMMM/YYYY"),
        moment(filters.date[1]).format("DD/MMMM/YYYY"),
      ];
      let response = await ws.getMasive(filters);
      if(response.type == "success" && response.data != false){
        this.peticion = response.data;
      }
    },
    closeModal () {
      this.resetPeticion();
      this.syncroSatModalModal = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    resetPeticion(){
      this.peticion.status = "";
      this.peticion.last_start_date = "";
      this.peticion.last_end_date = "";
      this.peticion.status_legend = "Sin realizar";
      this.peticion.cfdis = 0;
      this.peticion.cfdis_income = 0;
      this.peticion.cfdis_expense = 0;
      this.peticion.cfdis_payment = 0;
      this.peticion.cfdis_payroll = 0;
      this.peticion.cfdis_error = 0;
      this.peticion.cfdis_already_exists= 0;
      this.is_first =  false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {

  },
}
</script>

<style>
.content-sat div{
    border-bottom: 3px solid #e1e5ec;
}
    
</style>