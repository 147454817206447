import axios from 'axios'
import store from '../store'

export default {
    getListIncomes: (data) => axios.post('cfdis-import/list/incomes',data),
    getListExpenses: (data) => axios.post('cfdis-import/list/expenses',data),
    getListPayments: (data) => axios.post('cfdis-import/list/payments',data),
    getListPayrolls: (data) => axios.post('cfdis-import/list/payrolls',data),
    getListCount: (data) => axios.post('cfdis-import/list/count',data),
    getMasive: (data) => axios.post('cfdis-import/sync-sat',data),
    downloadMasive: (data) => axios.post('cfdis-import/sync-sat/download',data),
    downloadXML: (id) => axios.get('cfdis-import/'+id+'/download-xml').then(function(response){
        if(response.type == "success"){
            window.open(axios.defaults.baseURL+"/s3?file="+response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadPDF: (id) => axios.get('cfdis-import/'+id+'/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadExcel: (filters) => axios.post('cfdis-import/list/download-excel',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadZip: (filters) => axios.post('cfdis-import/list/download-zip',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),    

}