<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-copy"/> {{caption}}
        <div class="card-header-actions">
           <CRow>
            <div role="group" class="form-group" style="margin-top: 25px; ">
              <CButton color="info" size="sm">{{periodName}}</CButton>
            </div>
            <div role="group" class="form-group" style="margin-top: 25px; margin-right: 10px;">            
              <CDropdown color="info" togglerText="" size="sm">
                <CDropdownItem v-for="(range, i) in periods" :key="'range'+i"  @click="changePeriod(range)">{{range.name}}</CDropdownItem>
              </CDropdown>          
            </div>
            <div role="group" class="form-group" style="margin-top: 25px; margin-right: 10px;">
              <CButton color="success" size="sm" @click="downloadExcel" v-if="has_access">Exportar Excel</CButton>
              <CButton color="warning" size="sm" @click="downloadZip" v-if="has_access">Exportar Zip</CButton>
              <CButton color="info" size="sm" @click="openSyncroSatModal" v-if="has_access">Sincronizar SAT</CButton>
            </div>
          </CRow>     
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">  
          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('incomes')" class="nav-item">
              <a class="nav-link" :class="tab == 'incomes' ? 'active' : ''" aria-current="page">Ingresos <span v-show="totals.incomes >= 0">({{totals.incomes}})</span></a>
            </li>
            <li @click="changeTab('expenses')" class="nav-item">
              <a class="nav-link" :class="tab == 'expenses' ? 'active' : ''" aria-current="page">Egresos <span v-show="totals.expenses >= 0">({{totals.expenses}})</span></a>
            </li>
            <li @click="changeTab('payments')" class="nav-item">
              <a class="nav-link" :class="tab == 'payments' ? 'active' : ''" aria-current="page">Pagos <span v-show="totals.payments >= 0">({{totals.payments}})</span></a>
            </li>                        
            <li @click="changeTab('payrolls')" class="nav-item">
              <a class="nav-link" :class="tab == 'payrolls' ? 'active' : ''">Nómina <span v-show="totals.payrolls >= 0">({{totals.payrolls}})</span></a>
            </li> 
          </ul>    
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 30 : 10"
            :dark="dark"
            pagination
            class="listing"
          >
            <template #date="{item}"><td class="w100 text-center">{{ item.date | date }}</td></template>
            <template #folio="{item}"><td class="w150 text-center">{{ item.serial + item.folio }} / {{ item.uuid }}</td></template>
            <template #name="{item}"><td class="text-center">{{ item.emisor_rfc }} - {{ item.emisor_name }}</td></template>
            <template #subtotal="{item}"><td class="w150 text-right"><span style="float: left">$</span>{{ item.importe | currency }}</td></template>
            <template #discount="{item}"><td class="w150 text-right"><span style="float: left">$</span>{{ item.discount | currency }}</td></template>
            <template #transfered_taxes="{item}"><td class="w150 text-right"><span style="float: left">$</span>{{ item.transfered_taxes | currency }}</td></template>
            <template #withheld_taxes="{item}"><td class="w150 text-right"><span style="float: left">$</span>{{ item.withheld_taxes | currency }}</td></template>
            <template #total="{item}"><td class="w150 text-right"><span style="float: left">$</span>{{ item.total | currency }}</td></template>                                    
            <template #actions="{item}">
              <td class="table_actions b3">
                <table style="padding: 0px; margin: auto; ">
                 <tr>
                  <td style="padding: 0px; border: 0px !important;">
                    <CButton color="info" size="sm" @click="downloadXML(item.id)">XML</CButton>
                  </td>
                  <td style="padding: 0px; border: 0px !important;">
                    <CDropdown color="info" togglerText="" size="sm">
                      <CDropdownItem href="#" @click="downloadPDF(item.id)">PDF</CDropdownItem>
                    </CDropdown>
                  </td>
                  </tr>
                </table>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
    <SyncroSatModal ref="syncroSatModal" ></SyncroSatModal>
  </CCard>  
</template>

<script>
import store from '../../store'
import ws from '../../services/cfdis-import';
import SyncroSatModal from './modals/SyncroSatModal.vue';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import 'moment/locale/es';

moment.tz.setDefault('America/Mexico_city');

export default {
  name: 'CfdisImportTable',
  components: { SyncroSatModal},
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [
        {key: 'date', label: 'Fecha'},
        {key: 'folio', label: 'Folio / UUID'},
        {key: 'name', label: 'Razon Social'},
        {key: 'subtotal', label: 'Subtotal'},
        {key: 'discount', label: 'Descuentos'},
        {key: 'transfered_taxes', label: 'Trasladados'},
        {key: 'withheld_taxes', label: 'Retenidos'},
        {key: 'total', label: 'Total'},                
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      tab: 'incomes',
      totals: {
        incomes: -1,
        expenses: -1,
        active: -1,
        canceled: -1   ,     
      },
      filters: {},
      periods: [],
      periodName: "",
    }
  },
  mounted: async function() {
    this.loading();
    this.setPeriods();
    this.setFilters(this.periods[0]);

    await this.getTotals(this.filters);  

    this.$emit("refresh", "incomes" ,this.filters);

    this.loaded();
  },
  methods: {
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;        
        this.$emit("refresh", tab,this.filters );
      }
    },
    setFilters: function(range) {
      let params = {
        date: [
          moment(range.value.start).format("YYYY-MM-DD"),
          moment(range.value.end).format("YYYY-MM-DD"),
          ],
        is_first: range.is_first
      };
      this.filters = params;
      return this.filters;
    },
    async getTotals(){
      let response = await ws.getListCount(this.filters);
      if(response.type == "success"){
        this.totals.incomes = response.data.incomes;
        this.totals.expenses = response.data.expenses;
        this.totals.payments = response.data.payments;
        this.totals.payrolls = response.data.payrolls;
      }
    },    
    changePeriod: async function(range){
      this.periodName  = range.name;
      this.setFilters(range);
      this.getTotals();
      this.$emit("refresh", this.tab,this.filters );      
    },        
    setPeriods: function (by = "month") {
      this.periods = [];
      if(by == "month"){
        for (let index = 0; index < 12; index++) {
          this.periods.push({
            id: index,
            name: moment().startOf('month').subtract(index,"month").format("MMMM Y"),
            value: {
              start: moment().startOf('month').subtract(index,"month"),
              end: (index == 0) ?  moment() : moment().startOf('month').subtract(index,"month").endOf("month")
            },
            is_first: (index == 0) ? true : false,
          });                  
        }
        this.periodName  = moment().startOf('month').format("MMMM Y");
      }else{
        for (let index = 0; index < 12; index++) {
          this.periods.push({
            id: index,
            name: moment().startOf('month').subtract(index,"month").format("MMM Y") + " a " + moment().startOf('month').subtract(index,"month").add(1,"month").format("MMM Y"),
            value: {
              start: moment().startOf('month').subtract(index,"month"),
              end: moment().startOf('month').subtract(index,"month").add(1,"month"),
            }
          });                  
        }
        this.periodName  = moment().startOf('month').format("MMM Y") + " a " + moment().startOf('month').add(1,"month").format("MMM Y");
      }
    },         
    async openSyncroSatModal () {
      this.$refs.syncroSatModal.openModal(this.filters);
    },
    async downloadXML(id){
      ws.downloadXML(id);
    },
    async downloadPDF(id){
      ws.downloadPDF(id);
    },
    async downloadExcel(){
      this.loading();

      this.filters.type = this.tab;
      ws.downloadExcel(this.filters);

      this.loaded();
    },
    async downloadZip(){
      this.loading();

        let filters = this.filters;
        filters.type = this.tab;
        ws.downloadZip(filters);

      this.loaded();
    },    
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading'); 
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
