<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CfdisImportTable
          :items="cfdisImport"
          hover
          striped
          border
          small
          fixed
          caption="CFDI'S Recibidos"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CfdisImportTable from '../components/cfdisImport/CfdisImportTable.vue'
import ws from '../services/cfdis-import';
import store from '../store'

export default {
  name: 'CfdisImport',
  components: { CfdisImportTable },
  data: function () {
		return {
            cfdisImport: []
        }
  },
  mounted: async function() {
  },
  methods: {
    async refreshItems (tab,filters = {}){
      this.loading();
      let response;
      switch (tab) {
        case "incomes":          
           response = await ws.getListIncomes(filters); 
          break;
        case "expenses":          
           response = await ws.getListExpenses(filters); 
          break;
        case "payments":          
           response = await ws.getListPayments(filters); 
          break;
        case "payrolls":          
           response = await ws.getListPayrolls(filters); 
          break;                                    
        default:
          response = await ws.getListIncomes(filters); 
          break;
      }      
      if(response.type == "success"){
        this.cfdisImport = response.data;
      }

      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
